import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Swiper from 'react-id-swiper'

import '../assets/css/flickity.css'
import '../assets/css/swiper.min.css'
class Gallery extends Component {
  renderGallery(images) {
    const images2 = require.context('../assets/images/UI/', true)
    const imagePath = name => images2(name, true)
    const params = {
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      spaceBetween: 30,
    }

    if (!images) return

    const gallery = images.map((obj, i) => {
      const links = !obj.link
        ? undefined
        : obj.link.split(',').map((obj2, j) => {
            return (
              <span key={j}>
                <a href={obj2.trim()} target="_blank" rel="noopener noreferrer">
                  {obj2.trim()}
                </a>
                <br />
              </span>
            )
          })

      let imagenes2 = undefined
      if (obj.pics) {
        imagenes2 = obj.pics.split(',').map((obj2, j) => {
          return (
            <div>
              <div className="imagen_slider">
                <img
                  src={imagePath('./' + obj.prefix + '/' + obj2.trim())}
                  alt={i}
                  className="image"
                />
              </div>
            </div>
          )
        })
      }

      return (
        <article className="12u 12u$(xsmall) work-item" key={i}>
          <h4>
            <i>{obj.role}</i> @ {obj.company}
          </h4>
          {obj.period && <i className="period">{obj.period}</i>}
          <p>
            <strong>Description: </strong> {obj.description}
          </p>
          {obj.technologies && (
            <p>
              <strong>Stack: </strong> {obj.technologies}
            </p>
          )}
          {obj.stack && (
            <p>
              <strong>Stack: </strong> {obj.technologies}
            </p>
          )}
          <ul>
            {obj.stack &&
              obj.stack.map(i => {
                return <li>{i}</li>
              })}
          </ul>
          <br />
          <Swiper {...params}>{imagenes2}</Swiper>
          {links && <p>{links}</p>}
        </article>
      )
    })

    return <div className="row">{gallery}</div>
  }

  render() {
    const { images } = this.props

    return <div>{this.renderGallery(images)}</div>
  }
}

Gallery.displayName = 'Gallery'
Gallery.propTypes = {
  images: PropTypes.array,
}

export default Gallery
