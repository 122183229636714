import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
// import Lightbox from 'react-images'
import Gallery from '../components/Gallery'

const JOBS = [
  {
    id: '17',
    company:
      'Starcity Properties Inc. San Francisco, California, United States',
    description: `${`My main role was fullstack developer but with strong responsibility on the frontend side, leading the growth team, onboarding new engineers and helping with intern’s programs. We managed up to 4 projects per cycle under the Shape-Up methodology from basecamp. Other responsibilities: Provide engineering on-call support shifts.`}`,
    stack: [
      'ReactJS',
      'Node.js / Express',
      'Graphql - PostgreSQL',
      'ReactNative (Interfaces)',
      'Wordpress',
      'Strapi / Stripe / Cloudinary / Auth0',
    ],
    role: 'Senior Software Engineer',
    link: 'https://starcity.com',
    prefix: 'STARCITY',
    pics: '1.jpg,2.jpg,3.jpg,4.jpg',
    period: 'June 2020 - May 2021',
  },
  {
    id: '11',
    company: 'Momument Valley Inc.  Los Angeles, California, United States',
    description: `${`Development of E-commerce and Website Projects.
    Me.Market. Marketplace for books selling.  (MVP: Web, Admin Panel, BackendI) , 
    Classics of Strategy and Diplomacy (Web, Admin Panel, Backend API)`}`,
    stack: [
      'ReactJS / MaterialUI / Sketch',
      'NodeJS/Express / MongoDB',
      'AWS / Heroku',
      'Integrations: Stripe / Cloudinary / Mailjet',
    ],
    role: 'Full Stack Engineer',
    prefix: 'MONUMENTVALLEY',
    pics: '1.jpeg,2.jpeg,3.jpeg,4.jpeg,5.jpeg',
    period: 'March 2020 - June 2020',
  },
  {
    id: '10',
    company: 'Homitag Inc.  Los Angeles, California, United States',
    description:
      'Development of a Marketplace. Distributed team of 8 engineers, Developing Resellers Dashboard. Building Interfaces from Figma Designs',
    role: 'Sr. Frontend Engineer',
    stack: [
      'ReactJS',
      'ExpressJS',
      'Material UI Components',
      'Integrations:  Stripe / Fedex, Ups and other shipping services',
    ],
    prefix: 'HOMITAG',
    pics: '1.png,2.png,3.png,4.png,5.png',
    period: 'December 2019 - May 2020',
  },
  {
    id: '1',
    company: 'Papelera Darpel S.A, Buenos Aires Argentina',
    description: `${`'Business scencials Store (similar to Staples), Main App features: product barcode scanning and generate discount coupons. Building a Mobile App for Android and IOSs, PHP Api, CMS Development to generate and modify content.  Implementation of Push Notifications using Firebase Cloud M - Deployment of the app on Android and Ios Markets.`}`,
    role: 'Sr. Frontend Engineer',
    stack: [
      'Apache Cordova',
      'Javascript / Jquery',
      'Framework7',
      'HTML5',
      'CSS3',
      'Firebase Push Notifications',
      'IOS and Android Markets Deployments / TestFlight',
    ],
    link:
      'https://apps.apple.com/us/app/darpel/id1469490253, https://play.google.com/store/apps/details?id=com.gallagherbros.darpel&hl=en',
    prefix: 'DARPEL',
    pics: '1.jpeg,2.jpeg,3.jpeg,4.jpeg,5.jpeg,6.jpeg,7.jpeg',
    period: 'February 2019 - December 2019',
  },
  {
    id: '2',
    company: 'Toro Admin, Buenos Aires Argentina',
    description:
      'Web App and API Development for Apartments Buildings and Country Clubs managers. Main Features: Handle payments administration and communication to each apartment owner. Generate reports in PDF. Deployed Build and APi on Heroku and AWS.',
    stack: ['ReactJS', 'Bootstrap', 'NodeJS', 'PostgreSQL'],
    role: 'Frontend Remote Contactor',
    prefix: 'TORO',
    pics: '2.png,1.png,3.png,4.png,5.png,6.png,7.png',
    period: 'February 2016 - October 2018',
  },
  {
    id: '3',
    company: 'Miranda Grand S.R.L.',
    description: 'Food Delivery App',
    stack: ['ReactJS', 'Bootstrap', 'NodeJS', 'Mysql Database'],
    role: 'Full-Stack Remote Contactor',
    prefix: 'MIRANDA',
    link: 'http://pedidos.mirandagrand.com/',
    pics:
      '1.jpeg,2.jpeg,3.jpeg,4.jpeg,5.jpeg,6.jpeg,7.jpeg,8.jpeg,9.jpeg,10.jpeg,11.jpeg,12.jpeg,13.jpeg,14.jpeg',
  },
  {
    id: '4',
    company: 'Doctor Zwiener',
    description: 'Allergies Doctor Administration Tool',
    stack: ['ReactJS', 'Bootstrap', 'NodeJS', 'Mysql Database'],
    role: 'Full-Stack Remote Contactor',
    prefix: 'DRZWIENER',
    pics: '1.png,2.png,3.png,4.png,5.png,6.png',
  },
  {
    id: '5',
    company: 'Axionlog S.A',
    description: 'Corporate Website',
    stack: ['ReactJS', 'Bootstrap', 'NodeJS', 'PostgreSQL', 'Sketch UI Design'],
    role: 'Remote Contactor',
    link: 'https://americanfoodservice.com',
    prefix: 'AFS',
    pics: '1.jpeg,2.jpeg,3.jpeg,4.jpeg,5.jpeg,6.jpeg,7.jpeg',
  },
  {
    id: '6',
    company: 'Acerbrag S.A',
    description: 'Corporate Website',
    stack: ['Bootstrap', 'PHP/MYSQL', 'Sketch'],
    role: 'Remote Contactor',
    link: 'https://www.acerbrag.com',
    prefix: 'ACERBRAG',
    pics: '1.png,2.png,3.jpg',
  },
]

class HomeIndex extends React.Component {
  render() {
    const siteTitle = 'Luciano Mariani Portfolio'
    const siteDescription = 'Latest Projects'

    return (
      <Layout>
        <Helmet>
          <title>{siteTitle}</title>
          <meta name="description" content={siteDescription} />
        </Helmet>

        <div id="main">
          <section id="two">
            <h1>Work Experience</h1>
            <Gallery images={JOBS} />
          </section>

          <section id="three">
            <h2>Get In Touch</h2>
            <div className="row">
              <div className="12u 12u$(small)">
                <ul className="labeled-icons">
                  <li>
                    <h3 className="icon fa-home">
                      <span className="label">Address</span>
                    </h3>
                    Pilar, Buenos Aires
                    <br />
                    Argentina
                  </li>
                  <li>
                    <h3 className="icon fa-mobile">
                      <span className="label">Phone</span>
                    </h3>
                    <strong>+5491160563687</strong>
                  </li>
                  <li>
                    <h3 className="icon fa-envelope-o">
                      <span className="label">Email</span>
                    </h3>
                    <strong>contact at lucianomariani dot com</strong>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default HomeIndex
